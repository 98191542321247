import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/seafront-hotel-accommodation",
      title: "HOTEL",
      icon: "STAY-BLUE.png",
      iconHover: "STAY-SPRITZ.png",
    },
    {
      url: "/seafront-holiday-park-accommodation",
      title: "HOLIDAY PARK",
      icon: "ABOUT-BLUE.png",
      iconHover: "ABOUT-SPRITZ.png",
    },
    {
      url: "/seafront-connect",
      title: "CAR & E-BIKE HIRE",
      icon: "CARHIRE-BLUE.png",
      iconHover: "CARHIRE-SPRITZ.png",
    },
    {
      url: "/eat-and-drink",
      title: "EAT & DRINK",
      icon: "EAT&DRINK-BLUE.png",
      iconHover: "EAT&DRINK-SPRITZ.png",
    },
    {
      url: "/tours-and-experiences",
      title: "BOOK EXPERIENCES",
      icon: "THINGSTODO-BLUE.png",
      iconHover: "THINGSTODO-SPRITZ.png",
    },
  ];
  const { pathname } = useRouter();
  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };
  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/seaGreen/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/spritz/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}
