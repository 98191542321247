/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './hamburgerStyle';

export function Hamburger({ opened, toggleMenu, scrolled }) {
  return (
    <button
      css={[style.button, scrolled && style.buttonScrolled]}
      type="button"
      aria-label="Menu"
      onClick={toggleMenu}
    >
      <span css={style.hamburger}>
        <span
          className="line1"
          css={[style.line1, opened && style.lineActive1]}
        ></span>
        <span
          className="line2"
          css={[style.line2, opened && style.lineActive2]}
        ></span>
        <span
          className="line3"
          css={[style.line3, opened && style.lineActive3]}
        ></span>
      </span>
    </button>
  );
}
