import { CmsRadioSender } from './cms-radio-sender';
// //import * as $ from 'jquery';
// declare var CKEDITOR: any;
// import * as $ from 'jquery';

export class CmsHtmlEditor {
  ckeditorConfig;
  ckeditorStylesSet;
  sender;
  constructor(cmsEndpoint: string, sender: CmsRadioSender) {
    this.ckeditorConfig = {
      startupFocus: true,
      filebrowserBrowseUrl:
        cmsEndpoint + '/cms41helper/browse/selectpageormedia', //'/cms/browse',//'/cms/resource/selectfileckeditor',
      filebrowserImageBrowseUrl:
        cmsEndpoint + '/cms41helper/browse/selectmedia', //'/cms/browse?type=rc',//'/cms/resource/selectfileckeditor?type=Images',
      extraPlugins:
        'sourcedialog,youtubejaba,lineutils,widget,embedjaba,image2',
      toolbar: [
        {
          name: 'basicstyles',
          groups: ['basicstyles', 'cleanup'],
          items: [
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            'Subscript',
            'Superscript',
            '-',
            'RemoveFormat',
          ],
        },
        {
          name: 'paragraph',
          groups: ['list', 'align'],
          items: [
            'NumberedList',
            'BulletedList',
            '-',
            'JustifyLeft',
            'JustifyCenter',
            'JustifyRight',
            'JustifyBlock',
          ],
        },
        {
          name: 'links',
          items: ['Link', 'Unlink', 'Anchor'],
        },
        {
          name: 'insert',
          items: [
            'Image',
            'Table',
            'HorizontalRule',
            'PageBreak',
            'Iframe',
            'Youtube',
            'EmbedJaba',
          ],
        },
        '/',
        {
          name: 'document',
          groups: ['mode'],
          items: ['Sourcedialog'],
        },
        {
          name: 'clipboard',
          groups: ['clipboard', 'undo'],
          items: [
            'Cut',
            'Copy',
            'Paste',
            'PasteText',
            'PasteFromWord',
            '-',
            'Undo',
            'Redo',
          ],
        },
        {
          name: 'styles',
          items: ['Styles', 'Format'],
        },
        {
          name: 'colors',
          items: ['TextColor', 'BGColor'],
        },
        {
          name: 'tools',
          items: ['Maximize', 'ShowBlocks'],
        },
      ],
      extraAllowedContent: 'style;span;*[id,rel,data-*](*){*}',
    };

    this.ckeditorStylesSet = [
      /* Block Styles */

      // These styles are already available in the "Format" combo ("format" plugin),
      // so they are not needed here by default. You may enable them to avoid
      // placing the "Format" combo in the toolbar, maintaining the same features.
      /*
      { name: 'Paragraph',		element: 'p' },
      { name: 'Heading 1',		element: 'h1' },
      { name: 'Heading 2',		element: 'h2' },
      { name: 'Heading 3',		element: 'h3' },
      { name: 'Heading 4',		element: 'h4' },
      { name: 'Heading 5',		element: 'h5' },
      { name: 'Heading 6',		element: 'h6' },
      { name: 'Preformatted Text',element: 'pre' },
      { name: 'Address',			element: 'address' },
      */
      { name: 'Title', attributes: { class: 'title' } },
      // { name: 'Heading 3', element: 'h1', attributes: { 'class': 'h3' } },
      // { name: 'Super paragraph',		element: 'p', attributes: { 'class': 'superparagraph' } },

      //{ name: 'Italic Title',		element: 'h2', styles: { 'font-style': 'italic' } },
      //{ name: 'Subtitle',			element: 'h3', styles: { 'color': '#aaa', 'font-style': 'italic' } },
      //{
      //	name: 'Special Container',
      //	element: 'div',
      //	styles: {
      //		padding: '5px 10px',
      //		background: '#eee',
      //		border: '1px solid #ccc'
      //	}
      //},

      /* Inline Styles */

      // These are core styles available as toolbar buttons. You may opt enabling
      // some of them in the Styles combo, removing them from the toolbar.
      // (This requires the "stylescombo" plugin)
      /*
      { name: 'Strong',			element: 'strong', overrides: 'b' },
      { name: 'Emphasis',			element: 'em'	, overrides: 'i' },
      { name: 'Underline',		element: 'u' },
      { name: 'Strikethrough',	element: 'strike' },
      { name: 'Subscript',		element: 'sub' },
      { name: 'Superscript',		element: 'sup' },
      */

      //{ name: 'Marker',			element: 'span', attributes: { 'class': 'marker' } },

      //{ name: 'Big',				element: 'big' },
      //{ name: 'Small',			element: 'small' },
      //{ name: 'Typewriter',		element: 'tt' },

      //{ name: 'Computer Code',	element: 'code' },
      //{ name: 'Keyboard Phrase',	element: 'kbd' },
      //{ name: 'Sample Text',		element: 'samp' },
      //{ name: 'Variable',			element: 'var' },

      //{ name: 'Deleted Text',		element: 'del' },
      //{ name: 'Inserted Text',	element: 'ins' },

      //{ name: 'Cited Work',		element: 'cite' },
      //{ name: 'Inline Quotation',	element: 'q' },

      //{ name: 'Language: RTL',	element: 'span', attributes: { 'dir': 'rtl' } },
      //{ name: 'Language: LTR',	element: 'span', attributes: { 'dir': 'ltr' } },

      /* Object Styles */
      { name: 'Button', element: 'a', attributes: { class: 'button' } },
      {
        name: 'Secondary Button',
        element: 'a',
        attributes: { class: 'button secondary' },
      },
      {
        name: 'Third Button',
        element: 'a',
        attributes: { class: 'button third' },
      },
      { name: 'More link', element: 'a', attributes: { class: 'more' } },
      //{
      //	name: 'Styled image (left)',
      //	element: 'img',
      //	attributes: { 'class': 'left' }
      //},

      //{
      //	name: 'Styled image (right)',
      //	element: 'img',
      //	attributes: { 'class': 'right' }
      //},

      //{
      //	name: 'Compact table',
      //	element: 'table',
      //	attributes: {
      //		cellpadding: '5',
      //		cellspacing: '0',
      //		border: '1',
      //		bordercolor: '#ccc'
      //	},
      //	styles: {
      //		'border-collapse': 'collapse'
      //	}
      //},

      //{ name: 'Borderless Table',		element: 'table',	styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
      //{ name: 'Square Bulleted List',	element: 'ul',		styles: { 'list-style-type': 'square' } }
    ];

    this.sender = sender;
  }

  show($editor) {
    const CKEDITOR = window['CKEDITOR'] || {};
    //for ckeditor
    // console.log('ckeditor show');
    try {
      // We need to turn off the automatic editor creation first.
      CKEDITOR.disableAutoInline = true;
    } catch (e) {}

    var editor = CKEDITOR.inline('cmsCurrentEditor', this.ckeditorConfig);
    if (!CKEDITOR.stylesSet.get('default'))
      CKEDITOR.stylesSet.add('default', this.ckeditorStylesSet);

    var self = this;
    editor.on('change', function (evt) {
      // getData() returns CKEditor's HTML content.
      //$("#cmsCurrentEditor").addClass("dirtyHtml");
      //const itemId = $("#cmsCurrentEditor").data('cms-item-id');
      const ed = document.querySelector('#cmsCurrentEditor');
      ed.classList.add('dirtyHtml');
      const itemId = ed.getAttribute('data-cms-item-id');

      //console.log(itemId);
      self.sender.markDirtyHtml(itemId);
      //console.log('Total bytes: ' + evt.editor.getData().length);
    });
    editor.on(
      'paste',
      function (evt) {
        //console.log(evt.data.dataValue);
        //evt.data.dataValue = evt.data.dataValue.replace(/&nbsp;/g, '');
        //evt.data.dataValue = evt.data.dataValue.replace(/<p><\/p>/g, '');
        //editor.insertHtml(evt.data.dataValue); return false;
        setTimeout(function () {
          CKEDITOR.instances.cmsCurrentEditor.setData(
            CKEDITOR.instances.cmsCurrentEditor.getData()
          );
        }, 100);
      },
      null,
      null,
      9
    );
  }

  close($editor = null) {
    const CKEDITOR = window['CKEDITOR'] || {};
    //console.log('ckeditor close');
    if (typeof CKEDITOR.instances.cmsCurrentEditor != 'undefined')
      CKEDITOR.instances.cmsCurrentEditor.destroy();

    //for (var instance in CKEDITOR.instances) {
    //    CKEDITOR.instances[instance].destroy();
    //}
  }
}
