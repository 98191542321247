import { css } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(
    mq({
      height: [48, null, 60],
      background: 'transparent',
      position: 'fixed',
      width: '100%',
      zIndex: '999',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.3s',
    })
  ),
  topScrolled: css(
    mq({
      background: '#FFF5EB',
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: 'block',
      height: [43, null, 90],
      transition: 'all .5s',
      marginTop: ['0', null, '4rem'],
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [40, null, 50],
          marginTop: '0',
        })
      ),
    })
  ),
}
