import { mq } from '../../../cssInJs';

export default {
  pageTiles__fourth: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden',
    '&.slider': {
      display: 'block',
      overflow: 'unset',
      '.slick-prev:before, .slick-next:before': {
        color: 'black',
      },
      '.slick-prev': mq({
        left: ['0px', null, '-22px'],
      }),
      '.slick-next': mq({
        right: ['0px', null, '-22px'],
      }),
    },
  },
  pageTile__fourth: mq({
    flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%', '1 0 20%'],
    maxWidth: ['100%', null, '50%', '33.3%', '20%'],
    padding: '10px',
    '&.slider': {
      maxWidth: '100%',
      height: '100%',
    },
  }),
  pageTile__fourth__wrapper: {
    boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'rgb(222,212,191)',
  },
  pageTile__fourth__content: {
    '&:hover': {
      textDecoration: 'none',
      '.pageTile__fourth__bg': {
        transform: 'scale(1.05)',
      },
    },
  },
  pageTile__fourth__title: mq({
    fontSize: ['32px', null, '23px'],
    lineHeight: '1.1',
    padding: '0.5rem 0.5rem',
    color: 'rgb(26,45,19)',
    fontWeight: '600',
    maxWidth: '100%',
    textAlign: 'center',
  }),
  pageTile__fourth__bg: {
    paddingTop: '56.25%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'all .2s',
  },
  pageTile__fourth__des: {
    fontSize: '0.9rem',
    fontFamily: '"Roboto Condensed",sans-serif',
    lineHeight: 1.2,
    padding: '0.5rem 0.5rem',
    color: 'rgb(26,45,19)',
    flex: '1 0 auto',
    fontWeight: '400',
    paddingBottom: '1rem',
  },
  pageTile__fourth__button: {
    padding: '0rem 0.5rem 0.5rem',
    textAlign: 'center',
    button: {
      fontWeight: '600',
      padding: '10px 24px',
      fontSize: '0.8rem',
      lineHeight: '1',
      letterSpacing: '1px',
    },
  },
};
