//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from 'react-slick';
import { SiteLink } from '../../../components';

export function PageTilesBsyc(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, 'UseSlider') || 'default';
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
      useSlider={useSlider}
    ></Tile>
  ));
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles__fourth',
    'tilesBig',
    useSlider,
    item.cssClass || item.anchorName || ''
  );
  var settings = {
    dots: true,
    infinite: false,
    speed: 1500,
    fade: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles__fourth}
    >
      {useSlider && useSlider === 'slider' ? (
        <Slider {...settings}>{pageTileElts}</Slider>
      ) : (
        pageTileElts
      )}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  return (
    <div css={style.pageTile__fourth} className={` tileBig ${props.useSlider}`}>
      <div
        className="pageTile__content tile__content"
        css={style.pageTile__fourth__content}
      >
        <div
          css={style.pageTile__fourth__wrapper}
          className="pageTile__fourth__wrapper"
        >
          <div style={{ overflow: 'hidden' }} className="tile__bg">
            <div
              className="pageTile__fourth__bg"
              css={style.pageTile__fourth__bg}
              style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
            ></div>
          </div>
          <div className="pageTile__title_bg">
            <div className="pageTile__title_text">{page.pageTitle}</div>
          </div>
          <div className="pageTile_details tile__details">
            <div className="pageTile__title">{page.pageTitle}</div>
            <div className="pageTile_desc">{page.tileDesc}</div>
            {/* eslint-disable-next-line */}
            <SiteLink
              className="tile_button"
              to={disableLink ? '' : page.pageUrl}
            >
              READ MORE
            </SiteLink>
          </div>
        </div>
      </div>
    </div>
  );
}
